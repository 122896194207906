import React, { useState, useEffect } from "react";
import "./vendorSignup.css";
import { Button } from "react-bootstrap";
import NavLink from "react-bootstrap";

// https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete for accessibility, google loves a11y for ranking.

const VendorRequestForm = () => {

  const handleClick = () => {
    window.location.href = "https://www.simpletix.com/e/vendor-request-tickets-197701";
  };
  
  return (
    <section id="rsvp">
      <div className="rsvp-container">
        <div className="rsvp-content">
          <h2 className="rsvp-title">
            Vendor Applicatons
          </h2>
        </div>
        <div className="disclaimer">
        Our vendor application is currently open.
          <br />
        </div>
      </div>

      <div>
        <div className="vendor-desc" style={{ textAlign: "center", padding: "20px", color:"#e5dece" }}>
          <p>*After the Application and Agreement submission is recieved, IBRO will notify you if you are seleceted, then you will make a payment.</p>
          <p>You must submit both the Application form and the Agreement form to be considered.</p>
        </div>
      </div>

      <div className="vendor-link-btn">
        <button onClick={handleClick}>
          Go to Vendor Request
        </button>
      </div>
    </section>
  );
};

export default VendorRequestForm;
